









































import { unionBy } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import { RoleType, UserShortResource } from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class UsersMultipleSearch extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ default: 'Добавить' })
  private selectText!: string

  @Prop({ default: () => ([]) })
  private value!: UserShortResource[]

  @Prop({ default: true })
  private multiple!: boolean

  @Prop({ default: 'Поиск пользователей' })
  private label!: string

  @Prop({ default: 'Начните вводить для поиска' })
  private placeholder!: string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private searchMasters!: boolean

  @Prop({ default: () => ([]) })
  private roleTypes!: RoleType[]

  @Prop({ default: null })
  private timezoneGroupId!: number

  @Prop({ default: null })
  private courseId!: number

  @Prop({ default: null })
  private groupId!: number

  @Prop({ default: false })
  private unallocatedOnly!: boolean

  // Переносить ли выбранные пункты в селекте на несколько строк
  @Prop({ default: false })
  private isSelectedWrap!: boolean

  private search = ''
  private isLoading = false
  private usersFound: UserShortResource[] = []
  private usersSelected: UserShortResource[] = []

  private mounted () {
    this.usersFound = [...this.value]
    this.usersSelected = [...this.value]

    this.$bus.$on('add-users-to-group', () => {
      this.usersFound = []
      this.usersSelected = []
    })
  }

  private reset () {
    this.search = ''
    this.usersFound = []
    this.usersSelected = []
  }

  private handleInput () {
    this.search = ''
    this.$emit('update:value', this.usersSelected)
  }

  private handleDelete (item: UserShortResource) {
    const index = this.usersSelected.indexOf(item)
    if (index >= 0) {
      this.usersSelected.splice(index, 1)
      this.$emit('update:value', this.usersSelected)
    }
  }

  @Bind
  @Debounce(500)
  private handleSearch (query: string) {
    if (this.isLoading || !query || !query.trim()) return

    this.isLoading = true

    if (this.searchMasters) {
      DictionaryModule.searchMasters({
        courseId: this.courseId || undefined,
        groupId: this.groupId || undefined,
        query,
        timezoneGroupId: this.timezoneGroupId || undefined,
        unallocatedOnly: this.unallocatedOnly || undefined,
      })
        .then((response: UserShortResource[]) => {
          this.usersFound = unionBy(this.usersFound, response, 'id')
        })
        .catch(this.notifyError)
        .finally(() => (this.isLoading = false))
    } else {
      DictionaryModule.searchUsers({
        query,
        roleTypes: this.roleTypes,
      })
        .then((response: UserShortResource[]) => {
          this.usersFound = unionBy(this.usersFound, response, 'id')
        })
        .catch(this.notifyError)
        .finally(() => (this.isLoading = false))
    }
  }

  @Watch('search')
  private watchSearch (value: string) {
    if (value) {
      this.handleSearch(value)
    }
  }

  @Watch('timezoneGroupId')
  private watchTimezoneGroupId () {
    this.reset()
  }

  @Watch('courseId')
  private watchCourseId () {
    this.reset()
  }

  @Watch('groupId')
  private watchGroupId () {
    this.reset()
  }
}
