



























import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import CreateMasterGroupModal from '@/components/modals/masterGroups/CreateMasterGroupModal.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import { IMasterGroupsFilter } from '@/store/types'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
import { openNewTab } from '@/utils/functions'

@Component({
  components: {
    ButtonTextIcon,
    CreateMasterGroupModal,
    LinkTabs,
  },
})
export default class Groups extends Mixins(PermissionsMixin) {
  private showCreateMasterGroupModal = false

  private filter: IMasterGroupsFilter = GET_DEFAULT_TABLE_FILTER()
  private allocated = false

  private get tabs () {
    return [
      {
        name: 'Группы мастеров',
        to: { name: 'manager.control.groups.list' },
      },
      {
        name: 'Нераспределенные мастера',
        to: { name: 'manager.control.groups.masters' },
      },
    ]
  }

  private handleExport() {
    const endpoint = !this.allocated ? '/manager/master-groups/masters/unallocated/export' : '/manager/master-groups/export'

    openNewTab(endpoint, this.filter)
  }

  private handleChangeFilter(filter: { payload: IMasterGroupsFilter, allocated: boolean }) {
    this.allocated = filter.allocated

    this.filter = filter.payload
  }

  private mounted () {
    // Todo: редирект в зависимости от прав
    if (this.$route.name === 'manager.control.groups') {
      this.$router.replace({ name: 'manager.control.groups.list' })
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Группы',
    }
  }
}
