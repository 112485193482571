


































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import UsersMultipleSearch from '@/components/_uikit/search/UsersMultipleSearch.vue'
import CourseGroupMonthChainMixin from '@/mixins/CourseGroupMonthChainMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import {
  MasterGroupCreateRequest,
  NameValueChildrenResource,
  RoleType,
  UserShortResource,
} from '@/store/types'

@Component({
  components: {
    CourseSearch,
    Dialog,
    ModalWrapper,
    Select,
    UsersMultipleSearch,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CreateMasterGroupModal extends Mixins(CourseGroupMonthChainMixin, NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  private form: MasterGroupCreateRequest = {
    courseId: 0,
    groupId: null,
    masterIds: [],
    timezoneGroupId: 0,
  }

  private get timezoneGroups () {
    return DictionaryModule.timezoneGroups
  }

  private get roleTypes () {
    // RoleType.SPECIAL_MENTOR, если нужно будет еще наставников спецкурса получать в селекте
    return [RoleType.MENTOR]
  }

  private onCourseChange (course?: NameValueChildrenResource) {
    this.form.groupId = course && course.children.length ? course.children[0].value as number : null
  }

  private handleSelectMentors (users: UserShortResource[]) {
    this.form.mentorIds = users.map((user: UserShortResource) => user.id)
  }

  private handleSelectMasters (users: UserShortResource[]) {
    this.form.masterIds = users.map((user: UserShortResource) => user.id)
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerGroupsModule.createGroup(this.form)
            .then(() => {
              this.$bus.$emit('add-master-group')
              this.handleClose()
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose () {
    this.$emit('close')
  }
}
